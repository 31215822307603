'use client';

import { createBreakpoint } from 'react-use';

const screens = {
    phone: 0,
    tablet: 599,
    laptop: 959,
    desktop: 1279,
};

export type Breakpoint = keyof typeof breakpoints;
export const breakpoints = {
    phone: 0,
    tablet: screens.tablet,
    laptop: screens.laptop,
    desktop: screens.desktop,
};

export const useBreakpoint = createBreakpoint(breakpoints) as () => Breakpoint;
