import { cn } from '../../utils';
import { CheckIcon, ChevronRightIcon } from '@in2event/icons';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

interface DropdownMenuRootProps extends DropdownMenuPrimitive.DropdownMenuProps {
    className?: string;
}

const DropdownMenuRoot = forwardRef<HTMLDivElement, DropdownMenuRootProps>(
    ({ className, ...props }, forwardedRef) => {
        return (
            <div ref={forwardedRef} className={className}>
                <DropdownMenuPrimitive.Root {...props} />
            </div>
        );
    },
);

DropdownMenuRoot.displayName = 'DropdownMenuRoot';

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownMenuSeparator = DropdownMenuPrimitive.Separator;
const DropdownMenuSub = DropdownMenuPrimitive.Sub;
const DropdownMenuGroup = DropdownMenuPrimitive.Group;
const DropdownMenuItemIndicator = DropdownMenuPrimitive.ItemIndicator;
const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuContent = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Content>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, align = 'end', ...props }, forwardedRef) => {
    return (
        <DropdownMenuPrimitive.Portal>
            <DropdownMenuPrimitive.Content
                ref={forwardedRef}
                align={align}
                sideOffset={sideOffset}
                className={cn(
                    'z-50 min-w-45 overflow-hidden rounded-lg border bg-white py-2 shadow-elevation data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                    className,
                )}
                {...props}
            />
        </DropdownMenuPrimitive.Portal>
    );
});
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Item>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({ className, ...props }, forwardedRef) => {
    return (
        <DropdownMenuPrimitive.Item
            ref={forwardedRef}
            className={cn(
                'relative flex cursor-pointer select-none items-center gap-2 px-3 py-1.5 text-sm font-semibold text-neutral-250 outline-none transition-colors hover:bg-neutral-2100 hover:text-primary-600 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:[&>svg]:fill-current',
                className,
            )}
            {...props}
        />
    );
});
DropdownMenuItem.displayName = 'DropdownMenuItem';

const DropdownMenuCheckboxItem = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
    <DropdownMenuPrimitive.CheckboxItem
        ref={ref}
        className={cn(
            'relative flex cursor-default select-none items-center py-1.5 pl-8 pr-2 text-sm outline-none transition-colors hover:bg-neutral-2100 hover:text-primary-600 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:[&>svg]:fill-current',
            className,
        )}
        checked={checked}
        {...props}
    >
        <span className="absolute left-2 flex size-3.5 items-center justify-center">
            <DropdownMenuItemIndicator>
                <CheckIcon className="size-4" />
            </DropdownMenuItemIndicator>
        </span>
        {children}
    </DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName;

const DropdownMenuSubTrigger = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger>
>(({ className, children, ...props }, forwardedRef) => {
    return (
        <DropdownMenuPrimitive.SubTrigger
            ref={forwardedRef}
            {...props}
            className={cn(
                'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors hover:bg-neutral-2100 hover:text-primary-600 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 hover:[&>svg]:fill-current',
                className,
            )}
        >
            {children}
            <ChevronRightIcon className="size-4" />
        </DropdownMenuPrimitive.SubTrigger>
    );
});
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName;

const DropdownMenuSubContent = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.SubContent>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, children, ...props }, forwardedRef) => {
    return (
        <DropdownMenuPrimitive.Portal>
            <DropdownMenuPrimitive.SubContent
                className={cn(
                    'z-50 min-w-32 overflow-hidden rounded-lg border bg-white p-1 shadow-elevation data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
                    className,
                )}
                {...props}
                ref={forwardedRef}
            >
                {children}
            </DropdownMenuPrimitive.SubContent>
        </DropdownMenuPrimitive.Portal>
    );
});
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName;

const DropdownMenuSubItem = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Item>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>(({ className, children, ...props }, forwardedRef) => {
    return (
        <DropdownMenuPrimitive.Item
            ref={forwardedRef}
            {...props}
            className={cn(
                'relative flex cursor-default select-none items-center px-3 py-1.5 text-sm font-semibold text-neutral-250 outline-none transition-colors hover:bg-neutral-2100 hover:text-primary-600 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 hover:[&>svg]:fill-current',
                className,
            )}
        >
            {children}
        </DropdownMenuPrimitive.Item>
    );
});
DropdownMenuSubItem.displayName = 'DropdownMenuSubItem';

const DropdownMenuLabel = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Label>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label>
>(({ className, ...props }, ref) => (
    <DropdownMenuPrimitive.Label
        ref={ref}
        className={cn('px-2 py-1.5 text-sm font-semibold', className)}
        {...props}
    />
));
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

export {
    DropdownMenuRoot,
    DropdownMenuTrigger,
    DropdownMenuSeparator,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuCheckboxItem,
    DropdownMenuSub,
    DropdownMenuSubTrigger,
    DropdownMenuSubContent,
    DropdownMenuSubItem,
    DropdownMenuLabel,
    DropdownMenuItemIndicator,
    DropdownMenuGroup,
    DropdownMenuPortal,
};
